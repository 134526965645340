/**
 * Haitian Creole translation for bootstrap-datepicker
 */
export default {
  'ht-HT': {
    days: ["Dimanch", "Lendi", "Madi", "Mèkredi", "Jedi", "Vandredi", "Samdi"],
    daysShort: ["Dim", "Len", "Mad", "Mèk", "Jed", "Van", "Sam"],
    daysMin: ["Di", "Le", "Ma", "Mè", "Je", "Va", "Sa"],
    months: ["Janvye", "Fevriye", "Mas", "Avril", "Me", "Jen", "Jiyè", "Out", "Septanm", "Oktòb", "Novanm", "Desanm"],
    monthsShort: ["Jan", "Fev", "Mas", "Avr", "Me", "Jen", "Jiy", "Out", "Sep", "Okt", "Nov", "Des"],
    today: "Jodi a",
    monthsTitle: "Mwa yo",
    clear: "Efase",
    weekStart: 1,
    format: "dd/mm/yyyy"
  }
};
